@use 'styles/variables' as v;
@use 'styles/mixins' as m;
@use '@material/textfield';
@use '../balance-withdraw/balance-withdraw.scss' as withdraw-styles;

#balance-topup {
    @extend #balance-withdraw;

    .previous-card {
        min-width: 267px;
        min-height: 111px;
        background-color: v.$palegray;
        transition: background-color 0.5s ease, border-color 0.5s ease;
        border: 1px solid v.$transparent;

        .static-width {
            width: 173px;
            margin-left: 10px;

            @media (width <= v.$desktop) {
                width: 133px;
            }

            @media (width <= v.$tablet) {
                width: 58.33%;
            }
        }

        &.selected {
            border: 1px solid v.$cx-purple;
        }

        &.expired {
            border: 1px solid v.$red;
        }

        &.expiring-soon {
            border: 1px solid v.$yellow;
        }

        .card-image {
            max-width: 45px;
            width: 42px;
        }

        .card-billing-address {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .close-icon-card {
        height: 10px;
        opacity: 0;
        transition: visibility 0.3s linear, opacity 0.3s linear;
        visibility: hidden;
        filter: brightness(0%);
        width: 10px;

        @media (v.$tablet <= width <= v.$desktop) {
            &.close-icon-card-expired {
                margin-right: 6px;
            }
        }
    }

    .previous-card:hover {
        border: 1px solid rgba(87, 98, 213, 0.4);

        .close-icon-card {
            opacity: 1;
            visibility: visible;
        }
    }

    #help-icon img {
        height: 20px;
        position: absolute;
        right: 15px;
        top: 15px;
        filter: invert(100%);
    }

    .lock-icon {
        max-height: 16px;
        max-width: 13px;
    }

    .vgs-form-wrapper,
    .bs-checkout-area {
        max-width: 928px;
        margin-top: 20px;
    }

    .cx-google-pay-box button {
        border-radius: 6px !important;
        line-height: 19px;
        font-size: v.$medium !important;
        padding: 12px 16px !important;
    }
}
