@use 'styles/variables' as v;
@use 'styles/mixins' as m;
@use '@material/textfield';

#security-reset-password {
    .top-up-view-container {
        padding: v.$regularPad;
        @include m.flex-gap(v.$bigPad, $direction: column);
        height: 316px;

        @media (width <= v.$desktop) {
            padding: 0;
            border-radius: 0;
            box-shadow: none;
            background: transparent;
            gap: v.$regularPad;
        }

        p, h1, h2 {
            margin-bottom: 0 !important;
        }
    }

    cds-back-button {
        .return {
            gap: 7px;
        }
    }

    .password-update-form {
        @include m.flex-gap(v.$regularPad);
    
        cx-password-input {
            @include m.container-fluid(400px);
        }
    }

    @include m.media (v.$phone) {
        .password-update-form {
            flex-wrap: wrap;
    
            cx-password-input {
                @include m.container-fluid(100%);
            }
        }
    }

    .current-password-container .input-password {
        margin-bottom: 0;
    }

    .input-password {

        @media (width > v.$desktop) {
            &.skeleton-loading {
                height: 50px !important;
                width: 400px !important;
            }
        }

        @media (width <= v.$desktop) {
            &.skeleton-loading {
                height: 50px !important;
                width: 100% !important;
            }
        }
    }

    cds-back-button {
        z-index: 10;
    }

    .page-title {
        font-family: v.$primaryfont;
        font-size: v.$extralarge;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.45px;
        text-align: left;

        @media (width > v.$desktop) {
            &.skeleton-loading {
                height: 28px !important;
                width: 174px !important;
            }
        }

        @media (width <= v.$desktop) {
            &.skeleton-loading {
                height: 28px !important;
                width: 224px !important;
            }
        }

        @media (width <= v.$phone) {
            &.skeleton-loading {
                width: 200px !important;
            }
        }
    }

    .page-description {
        
        @media (width > v.$desktop) {
            &.skeleton-loading {
                height: 16px !important;
                width: 194px !important;
            }
        }

        @media (width <= v.$desktop) {
            &.skeleton-loading {
                height: 16px !important;
                width: 340px !important;
            }
        }

        @media (width <= v.$phone) {
            &.skeleton-loading {
                width: 220px !important;
            }
        }
    }

    .title-container {
        @include m.flex-gap(v.$tinyPad, $direction: column);

        span {
            opacity: 0.5;
        }
    }

    .payment-inputs-container {
        @include m.flex-gap(v.$regularPad);
        
        @media (width <= v.$phone) {
            flex-direction: column;
            gap: v.$smallPad;
        }
    }

    .input-style {
        @media (width >= v.$desktop) {
            max-width: 160px;
        }

        @media (width <= v.$phone) {
            width: 100% !important;
        }
    }

    .legend-label {
        @include m.flex(center);
        font-family: v.$primaryfont;
        font-size: v.$small;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.384px;

        > .legend-opacity {
            opacity: .75;
        }

        > .legend-balance {
            color: v.$dark-mustard !important;
        }
    }

    .static-font {
        font-size: v.$medium;
    }

    .payment-container {
        @include m.flex-gap(v.$smallPad, $direction: column);
    }

    .form-container {
        @include m.flex-gap(v.$regularPad, $direction: column);
    }

    .extra-info-containers {
        @include m.flex-gap(v.$smallPad);

        @media (width <= v.$phone) {
            flex-direction: column;
        }
    }

    .fees-container {
        @include m.flex-gap(v.$smallPad);
    }

    .payment-method-selector-class {
        width: 300px !important;
    }

    .phone-verification-area,
    .veriff-area {
        padding: v.$regularPad;
    }

    .veriff-btn {
        width: 178px;
        height: 50px !important;
    }

    .btn-style {
        width: 130px;
        font-size: v.$medium;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0.25px;

        @media (width <= v.$phone) {
            width: 100%;
            height: 50px;
        }
    }

    .purchase-flow-expandable {
        padding-inline: v.$regularPad !important;
        box-shadow: none !important;

        & .mdc-expandable__header {
            border-top: 1px v.$divisor-gray-light solid;
            padding: 0;

            &.mdc-expandable__header--closed,
            &.mdc-expandable__header--open {
                height: 64px !important;
                border-radius: 0;
            }

            .mdc-expandable__dropdown-icon {
                margin: 0 5px;
            }

            .expandable-item-arrow {
                color: v.$purple-text;
                transition: all 0.2s ease;
            }

            &.mdc-expandable__header--closed .expandable-item-arrow {
                transform: rotate(0deg);
            }

            &.mdc-expandable__header--open .expandable-item-arrow {
                transform: rotate(180deg);
            }


            &.mdc-expandable__header--focused {
                border-radius: 0 !important;
            }

            &.mdc-ripple-upgraded--background-focused::before {
                opacity: 0 !important;
            }

            &::before,
            &::after {
                background-color: v.$transparent;
            }
        }

        &.mdc-expandable--open {
            margin: 0 !important;
        }

        .mdc-expandable__content {
            padding: 0 0 v.$regularPad 0 !important;

            .drawer-subitem {
                padding: v.$smallPad v.$bigPad;
            }

            .mdc-expandable__content-container-sub .mdc-expandable__content {
                padding: 0 !important;
            }

            .item-flag {
                width: 30px;
                max-height: 20px;
                margin-right: 7px;
            }
        }
    }

    #cx-address-input {
        @media (width > v.$desktop) {
            .main-container {
                width: unset !important;
                margin: 0 !important;
            }

            .meetup-combo,
            .time-container {
                justify-content: space-evenly !important;
            }
        }
    }

    #cx-phone-input {
        @media (width <= v.$phone) {
            input,
            .iti {
                width: 100%;
            }
        }

        @media (width > v.$phone) {
            .circle-icon {
                right: unset;
                left: 268px;
            }
        }
    }

    .single-container {
        background: v.$white;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        flex: 1 1 auto;
        min-height: 124px;
        height: auto;
        padding: 37px 21px;
        flex-flow: column;
    }

    .email-container {
        background-color: v.$white;
        padding: 10px;
        border-radius: 12px;
        height: 345px;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);

        @media (max-width: v.$desktop) {
            height: 328px;
            margin-top: 2rem;
        }
    }

    .email-icon {
        max-width: 58px;
    }

    .info-text {
        padding: 10px;
        border-radius: 12px;
        background-color: v.$inputgray;
        color: v.$darkergray;
    }

    .warning-text {
        padding: 19px 10px;
        border-radius: 12px;
        background-color: v.$off-yellow;
        color: v.$darkergray;
    }

    .qr-box {
        padding: 12px;
        border-radius: 4px;
        background-color: v.$divisor-gray;
    }

    .green-text {
        padding: 19px 10px;
        border-radius: 12px;
        background-color: v.$off-green;
    }

    .mini-spinner-icon {
        position: absolute;
        right: 15px;
        top: 12px;
    }

    .circle-icon {
        position: absolute;
        height: 20px;
        width: 20px;
        right: 15px;
        top: 15px;
    }

    .mini-spinner-icon,
    .circle-icon {
        &.deactive {
            top: unset;
        }
    }

    button {
        height: 50px;
    }

    .two-factor-label {
        width:48%;
        height: 50px;
        @media (max-width: v.$desktop) and (min-width: v.$tablet) {
            width: 100%;
        }

        @media (max-width: v.$phone) {
            width: 100%;
        }
    }

    .two-factor-button {
        float: right;
        width: 50%;
        @media (max-width: v.$desktop) and (min-width: v.$tablet) {
            width: 100%;
        }

        @media (max-width: v.$phone) {
            width: 100%;
        }
    }

    .qr-code-img img {
        width: 90px;
        height: 94px;
    }

    .input-password:not(.mdc-text-field--disabled) .mdc-text-field__input {
        padding-right: 20px;
    }
    
    .link-email {
        color: v.$cx-purple;
    
        &:hover {
            text-decoration: underline !important;
        }
    }

    @media (max-width: v.$tablet) {
        .warning-text {
            padding: 10px 10px 11px;
        }
    
        .green-text {
            padding: 10px 10px 11px;
        }

        .qr-box {
            padding: 5px;
        }
    }

    .cx-form-control {        
        @include textfield.outline-shape-radius(v.$thinborder);
        border-radius: v.$thinborder;

        &.mdc-text-field--focused {
            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,    
            .mdc-notched-outline__trailing {
                border-width: 2px !important;
            }
        }
    }
}
