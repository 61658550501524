@use 'styles/variables' as v;
@use 'styles/mixins' as m;
@use 'styles/cg-grid' as grid;

// TODO: Delete with each new grid addition.
.portal-content .main {
    @include m.has-any((
        'cx-order-chat', 
        'cx-order-section', 
        '#support-page', 
        '#security', 
        '#verification', 
        '#order-details',
        '#support-chat',
        '#purchased-chat',
        '#swap-chat',
        '#sold-chat')) {
            max-width: 935px;
    }
}

.profile-grid {
    @include grid.cg-grid(v.$regularPad, 2, true, true);
    
    .profile-large-button,
    .notification__section {
        @include grid.cg-col(v.$regularPad, 2, 1, v.$desktop);
        @include grid.cg-col(v.$regularPad, 2, 2);
        width: 100%;
    }
}

#customer-app {
    @include m.regular-container-paddings(v.$desktop);

    @include m.media(v.$desktop) {
        &:has(#profile) .sidebar {
            flex: unset;
            height: 0;
        }
    }

    .container-portal-style,
    .container-profile-style,
    .container-profile-style-settings,
    .single-container {
        @include m.regular-border();
    }

    @include m.media(v.$desktop, false) {
        padding-inline: v.$regularPad;

        .portal-content {
            gap: v.$default-grid-spacing;
        }
    }

    .mdc-menu-surface {
        z-index: 5;
    }

    .main {
        width: 100%;
    }

    .section-title {
        color: v.$darkergray;
    }

    .portal-content {
        display: flex;
        @include m.container-fluid(v.$grid-width-desktop);
        margin-inline: auto;
    }

    .spin-loader {
        margin-top: 8rem;
    }

    .sidebar {
        z-index: 10;
        width: m.value-by-percent(248px, v.$grid-width-desktop);
        flex: none;
    }

    @include m.media(v.$widescreen) {
        .sidebar {
            flex: 0 0 v.$regularPad;
        }
    }

    @include m.media(v.$desktop) {
        .portal-content {
            padding-inline: v.$regularPad;
        }

        .sidebar {
            flex: unset;
            height: 0;
        }

        .portal-content:has(#profile) {
            padding-inline: 0;
        }

        .portal-content {
            flex-direction: column;
        }

        .main {
            margin-left: 0;
        }

        .main-container {
            margin-inline: 0;
        }
    }

    @include m.media(v.$phone) {
        .portal-content {
            flex-direction: column;
        }

        .spin-loader {
            margin-top: 5rem;

            .mdc-circular-progress {
                @include v.square(6.25rem)
            }
        }
    }
}
