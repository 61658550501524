@import 'styles/variables';
@import 'styles/mixins';

#crypto-asset-statements {
    font-family: $primaryfont;

    .header {
        margin-top: 65px;
        height: 255px;

        @media (max-width: $desktop) {
            height: 162px;
        }

        @media (max-width: $phone) {
            margin-top: 52px;
            height: 109px;
        }
    }

    hr {
        margin: 40px 0;
        border-top: 1px solid $purple-text;
    }

    .header-title {
        width: 90%;
        max-width: 922px;
        height: 60px;
        font-size: $extrahugeplustitle;
        font-weight: 700;
        line-height: 59px;
        letter-spacing: 1.6px;
        padding-top: 115px;
        margin: 0 auto;

        @media (max-width: $desktop) {
            max-width: 728px;
            font-size: $hugeplustitle;
            padding-top: 67px;
            line-height: 41px;
        }

        @media (max-width: $tablet) {
            font-size: $morehuge;
        }

        @media (max-width: $phone) {
            font-size: $extralarge;
            padding-top: 42px;
            line-height: 29px;
        }

        @media ($extrascreen <= width) {
            margin-left: 25.85%;
        }
    }

    .cas-body {
        max-width: 922px;
        margin: 0 auto;
        
        p {
            line-height: 19px;
            letter-spacing: 0.035px;
        }

        .margened-paragraph {
            p:has(strong) {
                margin-left: $extrahugetitle;
            }
        }

        @media ($extrascreen <= width) {
            margin-left: 25.85%;
        }

        @media (max-width: $desktop) {
            max-width: 728px;
        }

        @media (max-width: $tablet) {
            max-width: 728px;
            margin: 0 $regularPad;
        }
    }

    .custom-last-update div {
        width: 100%;
        @include flex(center, space-between);
    }

    .last-update p {
        color: $purple-text;
        font-size: $medium;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.25px;
        margin: 98px 0 38px 0;

        @media (max-width: $phone) {
            font-size: $regular;
        }
    }

    .last-update p:nth-child(1) {
        font-weight: 500;
        line-height: 18.75px;
        
        &, span {
            @include flex(center);
        }
        
        & span:last-child {
            font-weight: 500;
        }

        &, a {
            color: #464646!important;
            font-weight: 300;
            padding-bottom: 1px;
        }
        
        & a:hover {
            text-decoration: underline !important;
        }
    }

    .last-update p:nth-child(2) {
        display: none !important;
        font-size: $regular;
        letter-spacing: 0.45px;
        margin-block: auto;

        &, a {
            @include flex(center);
        }
    }

    @media (max-width: $tablet) {
        .last-update p {
            margin: 40px 0;
        }
        
        .last-update p:nth-child(1) {
            display: none !important;
        }

        .last-update p:nth-child(2) {
            display: flex !important;
        }
    }

    .cas-main-content {
        font-weight: 400px;
        width: 100%;
        letter-spacing: 0.51px;
    }

    .divider-bottom {
        width: 100%;
        margin-bottom: 79px !important;

        @media (max-width: $tablet) {
            margin-bottom: 97px !important;
        }

        @media (max-width: $phone) {
            margin-bottom: 64px !important;
        }
    }

    .divider {
        width: 100%;
        height: 1px;
        background: $primaryborder;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .text-title p {
        font-size: $extralarge;
        color: $purple-text;
        line-height: 28px;
        letter-spacing: 0.77px;
        margin-bottom: $regularPad;
    }

    .text-content p, ul, ol {
        font-size: $medium;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0.25px;
        color: $mediumblack;
        opacity: .75;
        margin-bottom: $regularPad;

        &:last-child {
            margin-bottom: 40px;
        }

        a {
            color: $purple-text;

            &:hover {
                text-decoration: underline !important;
            }
        }

        @media (max-width: $tablet) {
            font-size: $regular;
            line-height: 28px;
            letter-spacing: 0.1px;
        }
    }

    .text-content:not(:last-child)::after {
        content: '';
        display: block;
        width: 100%;
        margin: 40px auto;
        height: .5px;
        background-color: $purple-text;
    }

    .hide-botton-line::after {
        content: '';
        margin: 0 80px auto !important;
        background-color: transparent !important;
    }

    ol, ul {
        margin-left: 40px;
        padding-left: 1rem;
    }

    .custom-information-collection p:nth-child(n+3), .custom-changes-privacy-policy p {
        margin-bottom: 0;
    }

    .custom-we-use {
        &, p:nth-child(1), p:nth-child(4), ul {
            margin-bottom: 31px;
        }
    }

    .custom-counter-notification {
        &, ol, ul {
            list-style-type: none;
            padding: 0;
        }
    }

    .custom-notice-ue-residents p:has(u) {
        margin-bottom: 0;
    }

    .custom-cookies {
        & ol, ul {
            list-style-type: none;
            padding: 10px 0 0;
            margin-left: $regularPad !important;

            li {
                margin-bottom: $regularPad;
            }
        }
    }

    .custom-counter-notification {
        &, ol, ul {
            list-style-type: none;
            padding: 0;

            li {
                margin-bottom: $regularPad;
            }
        }
    }

    .content-mode-desktop {
        display: block;
    }
    
    .content-mode-mobile {
        display: none;
    }

    @media (width <= $tablet ) {
        .content-mode-desktop {
            display: none;
        }
        
        .content-mode-mobile {
            display: block;
        }
    }
}
