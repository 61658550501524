@use 'styles/variables' as v;
@use 'styles/mixins' as m;
@use '@material/textfield';

#security-two-fa-settings {
    .mini-spinner-icon {
        right: 16px;
    }

    .circle-icon {
        right: v.$regularPad;
    }

    .input-password {
        padding-right: v.$largePad;
    }

    cds-back-button .return {
        font-weight: 500;
    }

    .cx-notice-box {
        &.indications {
            .notice-header {
                font-size: v.$medium;
                line-height: v.$medium;
                letter-spacing: .16px;
                margin-bottom: v.$smallPad;
            }

            .main-message:not(:last-child) {
                padding-bottom: v.$regularPad !important;
            }
        }

        &:not(.indications) .main-message {
            padding-bottom: v.$regularPad !important;
        }
    }

    .code-container {
        @include m.flex-gap(v.$regularPad);
    }

    .qr-box {
        padding: 12px;
        border-radius: 4px;
        background-color: v.$white;
        color: v.$shadow;
        margin: 1.15rem 0;
    }

    .qr-code-img {
        position: relative;
        $transition: .2s;
        transition: all $transition;
        height: fit-content;

        &:not(.enabled) {
            @include m.showOnHover('.copy-icon', $transition);
        }

        cds-icon {
            position: absolute;
        }

        &.icon-only cds-icon {
            position: relative;
        }

        img:not(.copy-icon),
        cds-icon {
            @include m.square(70px);
            
        }

        img:not(.copy-icon) {
            transition: opacity $transition;
            opacity: 1;
        }

        .copy-icon {
            @include m.absoluteCenter();
            transform: scaleY(-100%);
            color: v.$cx-purple;
        }

        &:hover,
        &.enabled {
            background-color: v.$backgroundcontrol;
            border-radius: 5px;

            img:not(.copy-icon) {
                opacity: 0;
            }
        }

        &.enabled .copy-icon {
            transform: none;
        }

    }

    form[name="2fa"] {
        @include m.container-fluid(450px);
        display: flex;
        flex-direction: column;
    }

    .top-up-view-container {
        padding: v.$regularPad;
        max-width: 928px;
        @include m.flex-gap(v.$regularPad, $direction: column);

        @media (width <= v.$desktop) {
            padding: 0;
            border-radius: 0;
            box-shadow: none;
            background: transparent;
            gap: v.$regularPad;
        }

        p, h1, h2 {
            margin-bottom: 0 !important;
        }
    }

    cds-back-button {
        z-index: 10;
    }

    .page-title {
        font-family: v.$primaryfont;
        font-size: v.$extralarge;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.45px;
        text-align: left;
    }

    .title-container {
        @include m.flex-gap(v.$smallPad, $direction: column);
    }

    .payment-inputs-container {
        @include m.flex-gap(v.$regularPad);
        
        @media (width <= v.$phone) {
            flex-direction: column;
            gap: v.$smallPad;
        }
    }

    .input-style {
        @media (width >= v.$desktop) {
            max-width: 160px;
        }

        @media (width <= v.$phone) {
            width: 100% !important;
        }
    }

    .legend-label {
        @include m.flex(center);
        font-family: v.$primaryfont;
        font-size: v.$small;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.384px;

        > .legend-opacity {
            opacity: .75;
        }

        > .legend-balance {
            color: v.$dark-mustard !important;
        }
    }

    .static-font {
        font-size: v.$medium;
    }

    .payment-container {
        @include m.flex-gap(v.$smallPad, $direction: column);
    }

    .input-2fa-container {
        margin-top: v.$bigPad;

        @media (width <= v.$phone) {
            margin-top: v.$regularPad;
        }
    }

    .form-container {
        min-height: auto;
    }

    .extra-info-containers {
        @include m.flex-gap(v.$smallPad);

        @media (width <= v.$phone) {
            flex-direction: column;
        }
    }

    .fees-container {
        @include m.flex-gap(v.$smallPad);
    }

    .payment-method-selector-class {
        width: 300px !important;
    }

    .phone-verification-area,
    .veriff-area {
        padding: v.$regularPad;
    }

    .veriff-btn {
        width: 178px;
        height: 50px !important;
    }

    .btn-style {
        width: 130px;
        font-size: v.$medium;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0.25px;

        @media (width <= v.$phone) {
            width: 100%;
            height: 50px;
        }
    }

    .purchase-flow-expandable {
        padding-inline: v.$regularPad !important;
        box-shadow: none !important;

        & .mdc-expandable__header {
            border-top: 1px v.$divisor-gray-light solid;
            padding: 0;

            &.mdc-expandable__header--closed,
            &.mdc-expandable__header--open {
                height: 64px !important;
                border-radius: 0;
            }

            .mdc-expandable__dropdown-icon {
                margin: 0 5px;
            }

            .expandable-item-arrow {
                color: v.$purple-text;
                transition: all 0.2s ease;
            }

            &.mdc-expandable__header--closed .expandable-item-arrow {
                transform: rotate(0deg);
            }

            &.mdc-expandable__header--open .expandable-item-arrow {
                transform: rotate(180deg);
            }


            &.mdc-expandable__header--focused {
                border-radius: 0 !important;
            }

            &.mdc-ripple-upgraded--background-focused::before {
                opacity: 0 !important;
            }

            &::before,
            &::after {
                background-color: v.$transparent;
            }
        }

        &.mdc-expandable--open {
            margin: 0 !important;
        }

        .mdc-expandable__content {
            padding: 0 0 v.$regularPad 0 !important;

            .drawer-subitem {
                padding: v.$smallPad v.$bigPad;
            }

            .mdc-expandable__content-container-sub .mdc-expandable__content {
                padding: 0 !important;
            }

            .item-flag {
                width: 30px;
                max-height: 20px;
                margin-right: 7px;
            }
        }
    }

    #cx-address-input {
        @media (width > v.$desktop) {
            .main-container {
                width: unset !important;
                margin: 0 !important;
            }

            .meetup-combo,
            .time-container {
                justify-content: space-evenly !important;
            }
        }
    }

    #cx-phone-input {
        @media (width <= v.$phone) {
            input,
            .iti {
                width: 100%;
            }
        }

        @media (width > v.$phone) {
            .circle-icon {
                right: unset;
                left: 268px;
            }
        }
    }

    @include m.media (v.$tablet) {
        .code-container {
            align-items: stretch;

            &:not:has(.email-container) {
                min-height: 90px;
            }

            .qrImage {
                &,
                & img {
                    height: 100%;
                }

                img {
                    object-fit: contain;
                }

            }
        }
    }

    @include m.media (v.$phone) {
        .main-message {
            font-size: v.$small;
        }
    
        .cx-notice-box:not(.indications) .main-message {
            padding-bottom: v.$smallPad !important;
        }

        .code-container {
            gap: v.$smallPad;
        }

        .two-factor-button {
            z-index: 1;
        
            span {
                margin-bottom: -(v.$medium) !important;
            }
        }
    }

    .mb-20px{
        margin-bottom: 20px;
    }

    .two-factor-button {
        @include m.flex(initial, flex-end);
        width: fit-content;
        margin-left: auto !important;
    
        span {
            line-height: 19px;
        }
    
        .material-icons {
            @include m.flex-center;
        }
    
        &__text {
            text-decoration: underline;
        }
    }

    .cx-form-control {        
        @include textfield.outline-shape-radius(v.$thinborder);
        border-radius: v.$thinborder;

        &.mdc-text-field--focused {
            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,    
            .mdc-notched-outline__trailing {
                border-width: 2px !important;
            }
        }
    }
}
