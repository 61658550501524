@use 'styles/variables' as v;
@use 'styles/mixins' as m;
@use '@material/textfield';

#balance-withdraw {
    .balance-view-container {
        padding: v.$regularPad;
        max-width: 928px;
        @include m.column-gap(v.$regularPad);

        @media (width <= v.$desktop) {
            padding: 0;
            border-radius: 0;
            box-shadow: none;
            background: transparent;
        }

        p, h1, h2 {
            margin-bottom: 0 !important;
        }
    }

    cds-back-button {
        z-index: 10;
    }

    cx-text-input:not(cx-payoutable-form cx-text-input) {
        width: fit-content;
    }

    .page-title {
        font: 500 #{v.$extralarge}/32px v.$primaryfont;
        letter-spacing: 0.45px;
        text-align: left;
        color: v.$black;
    }

    .title-container {
        @include m.flex-gap(v.$smallPad, $direction: column);

        > p {
            line-height: 14px;

            > span {
                color: v.$black;
            }
        }
    }

    .payment-inputs-container {
        @include m.flex-gap(v.$regularPad);

        @media (width <= v.$phone) {
            flex-direction: column;
            gap: v.$smallPad;
        }

        &.password-inputs {
            margin-top: -#{v.$regularPad};
            @include m.flex($direction: column);
            gap: 5px !important;
        }
    }

    .input-style {
        @include textfield.outline-shape-radius(4px);
        border-radius: 4px;

        @media (width > v.$phone) {
            max-width: 160px;
        }

        @media (width <= v.$phone) {
            width: 100% !important;
        }

        &.password-style {
            @media (width > v.$phone) {
                max-width: 300px;
                width: 100% !important;
            }
        }
    }

    .quantity-input {
        &.balance-password-input {
            position: relative;

            @media (width > v.$phone) {
                max-width: 300px;
            }

            input {
                color: v.$black !important;
            }

            .material-icons {
                font-size: v.$medium !important;
            }
        }

        input {
            font-size: v.$small !important;
        }

        .mdc-text-field__affix--prefix {
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0.001em;
            text-align: left;
            font-size: v.$regular !important;
            color: v.$gray-blue;
            padding-right: v.$smallPad !important;
        }
    }

    .payment-method-selector {
        @media (width > v.$phone) {
            max-width: 300px !important;
        }
    }

    .payment-method-selector-class {
        @media (width > v.$phone) {
            width: 280px !important;
        }
    }

    .legend-label {
        @include m.flex(center);
        @include m.font(v.$regular, 400, 14px, 0.384px);
        color: v.$color-grey-700;

        > .legend-opacity.pending-balance {
            @include m.font(v.$regular, 500, 16px, 0.45px, v.$gray-blue, 1);
        }

        > .legend-balance {
            @include m.font(v.$regular, null, 16px, 0.001em);
        }
    }

    .static-font {
        font-size: 16px;
    }

    .payment-container {
        @include m.flex-gap(v.$smallPad, $direction: column);
    }

    .form-container {
        @include m.flex-gap(v.$regularPad, $direction: column);
    }

    .extra-info-containers {
        @include m.flex-gap(12px);

        @media (width <= v.$phone) {
            flex-direction: column;
        }
    }

    .fees-container {
        @include m.flex-gap(12px);
    }

    .navbar-btn {
        border-radius: 6px !important;
    }

    .phone-verification-area,
    .veriff-area {
        padding: v.$regularPad;
    }

    .veriff-btn {
        width: 178px;
        height: 50px !important;
    }

    .btn-style {
        @include m.container-fluid(300px, false, 150px, fit-content);
        @include m.font(v.$medium !important, 500, 19px, 0.25px);

        @media (width <= v.$phone) {
            width: 100%;
            height: 50px;
        }

        .navbar-btn {
            font-size: v.$medium !important;
        }
    }

    .purchase-flow-expandable {
        padding-inline: v.$regularPad !important;
        box-shadow: none !important;

        & .mdc-expandable__header {
            border-top: 1px v.$divisor-gray-light solid;
            padding: 0;

            &.mdc-expandable__header--closed,
            &.mdc-expandable__header--open {
                height: 64px !important;
                border-radius: 0;
            }

            .mdc-expandable__dropdown-icon {
                margin: 0 5px;
            }

            .expandable-item-arrow {
                color: v.$purple-text;
                transition: all 0.2s ease;
            }

            &.mdc-expandable__header--closed .expandable-item-arrow {
                transform: rotate(0deg);
            }

            &.mdc-expandable__header--open .expandable-item-arrow {
                transform: rotate(180deg);
            }


            &.mdc-expandable__header--focused {
                border-radius: 0 !important;
            }

            &.mdc-ripple-upgraded--background-focused::before {
                opacity: 0 !important;
            }

            &::before,
            &::after {
                background-color: v.$transparent;
            }
        }

        &.mdc-expandable--open {
            margin: 0 !important;
        }

        .mdc-expandable__content {
            padding: 0 0 v.$regularPad 0 !important;

            .drawer-subitem {
                padding: v.$smallPad v.$bigPad;
            }

            .mdc-expandable__content-container-sub .mdc-expandable__content {
                padding: 0 !important;
            }

            .item-flag {
                width: 30px;
                max-height: 20px;
                margin-right: 7px;
            }
        }
    }

    #cx-address-input {
        @media (width > v.$desktop) {
            .main-container {
                width: unset !important;
                margin: 0 !important;
            }

            .meetup-combo,
            .time-container {
                justify-content: space-evenly !important;
            }
        }
    }

    #cx-phone-input {
        @media (width <= v.$phone) {
            input,
            .iti {
                width: 100%;
            }
        }

        @media (width > v.$phone) {
            .circle-icon {
                right: unset;
                left: 268px;
            }
        }
    }

    .password-back-button {
        @include m.flex(center);
        margin-left: -7px;
        width: fit-content;

        .material-icons {
            color: v.$purple-text;
            font-size: v.$largeplus;
        }

        > span {
            font: 400 #{v.$regular}/16px v.$primaryfont;
            color: v.$purple-text;
            letter-spacing: 0.001em;
            opacity: .75;
        }
    }

    #cx-info-banner {
        p {
            line-height: 2;
            margin-bottom: 1.5rem !important;
        }

        ul {
            margin-bottom: 0 !important;
            padding-left: v.$regularPad;
        }

        li {
            font-size: v.$regular;
            font-weight: 400;
            margin-bottom: 12px;
        }
    }

    @include m.media(v.$desktop, false) {
        cds-back-button {
            padding-bottom: v.$regularPad;
        }
    }

    @media (width <= v.$phone) {
        .fees-container {
            flex-direction: column;
            gap: 8px;
        }

        .btn-style {
            max-width: 100%;

            button {
                padding: v.$smallPad v.$regularPad;
            }
        }
    }
    .amount-input {
        --cds-input-background: rgb(249, 249, 249);
        --cds-input-hover-background: rgb(249, 249, 249);
        --cds-text-field-width: 160px;
        --md-filled-text-field-input-text-size: 12px;
        --md-filled-text-field-input-text-prefix-trailing-space: 10px;
        --md-filled-text-field-input-text-prefix-color: rgba(86, 89, 116, 1);
        --md-filled-text-field-input-text-prefix-size: 14px;
    }
    
    .amount-wrapper:hover #cx-number-arrows {
        opacity: 1;
        visibility: visible;
    }
}
