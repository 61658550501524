@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#referrals {
    --cds-primary-color: #310FA2;

    --cds-text-field-insert-padding: 0;
    --cds-text-field-default-border: 2px solid #EEE;
    --cds-text-field-width: 100%;
    --cds-text-field-input-overflow: ellipsis;

    --md-filled-text-field-container-color: #F2F2F2;
    --md-filled-text-field-input-text-size: 12px;
    --md-filled-text-field-label-text-color: #858585;
    --md-filled-text-field-focus-label-text-color: #858585;
    --md-filled-text-field-label-text-size: 14px;
    --md-filled-text-field-trailing-icon-color: #060606;

    --cds-button-width: 87px;
    --cds-button-height: 52px;
    --md-filled-button-container-shape: 4px;
    --md-filled-button-label-text-size: 14px;
    --md-filled-button-container-color: #310FA2;
    --md-filled-button-leading-space: 14px;
    --md-filled-button-trailing-space: 14px;

    --cds-menu-width: 100%;
    --cds-menu-container-border: 1px solid #F0F0F0;
    --cds-menu-max-height: 260px;
    --cds-menu-padding: 0;
    --md-menu-container-color: #FFF;
    --md-menu-container-elevation: 0;

    --md-icon-button-state-layer-height: 52px;
    --md-icon-button-state-layer-width: 52px;
    --md-icon-button-state-layer-shape: 4px;

    --md-dialog-headline-line-height: 0;
    --md-dialog-headline-size: 2px;

    --cds-text-field-trailing-icon-size: 12px;
    --cds-text-field-loading-spinner-size: 24px;
    --cds-back-button-gap: 6px;

    .menu-container {
        width: 100%;
    }

    .dialog {
        width: 384px;

        .copy-button {
            margin-right: 0;
        }
    }

    .icons-container {
        @include m.flex(center, center);
        gap: 16px;
        flex-wrap: wrap;
        --md-filled-icon-button-container-color: #310FA2;
        --md-filled-icon-button-container-height: 24px;
        --md-filled-icon-button-container-width: 24px;  
        --md-filled-icon-button-icon-size: 14px;
    }

    .text-field-status {
        --cds-text-field-trailing-icon-size: 18px;
    }
    
    .share-icon {
        @include m.square(24px);
        transition: all .3s ease-in-out;

        &:hover {
            transform: scale(1.1);
        }
    }

    hr {
        opacity: 1;
        color: #E7E7E7;
        margin: 20px 0 !important;
    }

    .font-override {
        font-family: Roboto;
        font-size: v.$regular;
    }

    .referral-benefits {
        .menu-container {
            --cds-menu-anchor-width: auto;
            --cds-menu-width: 336px;
            md-icon {
                color: v.$purple-text;
            }
        }
    }

    .button {
        @include m.flex(center, center);
        width: 52px;
        height: 52px;
        border-radius: 4px;
    }

    .qr-button {
        background-color: #DDDDFF;
        --md-icon-button-icon-color: #310FA2;
        --md-icon-button-hover-icon-color: #310FA2;
        --md-icon-button-pressed-icon-color: #310FA2;
        --md-icon-button-focus-icon-color: #310FA2;
    }

    .copy-button {
        background-color: #310FA2;
        margin-left: 16px;
        margin-right: 8px;
        --md-icon-button-icon-color: #FFF;
        --md-icon-button-hover-icon-color: #FFF;
        --md-icon-button-pressed-icon-color: #FFF;
        --md-icon-button-focus-icon-color: #FFF;
        --md-icon-button-hover-state-layer-color: #4b20d8;
        --md-icon-button-hover-state-layer-opacity: .5;
    }

    .referral-option {
        @include m.flex(center);
        background-color: v.$white;
        color: v.$color-grey-1100;
        height: 52px;
        padding: 0 16px;
        font-size: v.$small;
        --md-icon-size: 20px;

        md-icon {
            color: #565974;
        }

        &:hover {
            background-color: #F6F6FD;
            cursor: pointer;
        }

        .option-text {
            width: 336px;
        }
    }

    .referral-option:last-of-type {
        md-icon {
            color: #310FA2;
        }
    }

    .text-small-static {
        font-size: 12px;
        color: v.$black;
    }

    .container-profile-style {
        padding: v.$regularPad;
    }

    cds-back-button {
        padding-block: 0;
        padding-bottom: v.$bigPad;

        @include m.media(v.$tablet) {
            padding-bottom: v.$regularPad;
        }

        @include m.media(v.$phone) {
            padding-top: 0;
        }
    }

    .container-padding {
        padding: v.$regularPad 0 v.$regularPad v.$regularPad !important;
    }

    .manage-box {
        width: 50%;
        height: 148px;
    }

    .stats-box {
        @include m.flex(initial, between);
        width: 50%;
        gap: v.$regularPad;
    }

    cx-rate-of-change {
        width: 50%;
        max-width: 299px;
    }

    .manage-box:not(cx-rate-of-change) {
        @include m.box-default();
    }

    .stat {
        @include m.box-default();
    }

    .referral-header {
        @include m.flex(center);
    }

    .header-subtitle {
        color: rgba(82, 82, 82, 1);
    }

    .header-action {
        max-width: 300px;
        color: v.$purple-text;
    }

    .menu-action {
        --md-icon-size: 18px;

        md-icon {
            color: v.$purple-text;
        }
    }

    .referral-inner {
        @include m.flex-column();

        &__title {
            line-height: 28px;

            @include m.media(v.$desktop, false) {
                font-weight: 700;
            }
        }

        &__desc {
            margin-top: 3px;
            line-height: 16px;
            letter-spacing: 0px;
        }
    }

    .referral-benefits {
        margin-bottom: v.$bigPad;
        @include m.flex-column();

        .menu-container {
            --cds-menu-width: 336px;

            md-icon {
                color: v.$purple-text;
            }

            &:hover {
                .header-action {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .rotate {
            transform: rotate(180deg);
        }

        cds-large-button {
            height: 100%;
            max-width: 299px;
            --cds-large-button-truncate-lines: 3;
            --cds-large-button-truncate-word-break: normal;
        }   

        .benefits-inner {
            min-height: 90px;
            gap: v.$regularPad;
            margin-top: v.$regularPad;
            @include m.flex(initial, space-between);
        }

        .benefit p {
            line-height: 16px;
            font-size: v.$regular !important;
            letter-spacing: 0;
            color: v.$black;
        }
    }

    .referral-options {
        &__title {
            line-height: 19px;
            letter-spacing: 0;
        }

        .options-inner {
            margin-top: v.$regularPad;
            gap: v.$regularPad;
            flex-direction: row;

            cds-large-button {
                width: 50%;
            }
        }

        .option {
            background-color: v.$primarybackground;
            border-radius: 4px;
            padding: 16px;
            gap: v.$smallPad;

            &:not(.option--disabled) {
                cursor: pointer;
                &:hover {
                    .arrow {
                        display: block;
                    }
                }
            }

            transition: all .3s ease-in-out;
        }

        .option .arrow {
            margin-left: auto;
            margin-right: 4px;
            display: none;
        }

        .option-icon {
            @include m.square(35px)
        }
    }

    .manage-referrals {
        margin: v.$regularPad 0;
        @include m.flex-column();

        .manage-inner {
            gap: v.$regularPad;
            @include m.flex(initial, space-between);
        }

        .link-combo {
            @include m.flex(center);
            width: 100%;
        }

        .reverse-arrow {
            transform: rotate(180deg)
        }
    }

    .filter-select  {
        margin-right: v.$regularPad;

        section[data-simplebar] {
            height: auto !important;
        }

    }

    .referrals-area {
        padding-right: v.$regularPad;
    }

    section[data-simplebar] {
        max-height: unset;
        height: 400px !important;
    }

    .filter-header {
        justify-content: space-between;
    }

    .search-bar {
        margin-top: 15px;
        margin-bottom: v.$regularPad;
    }

    .main-row {
        margin-top: v.$bigPad;
        margin-bottom: v.$regularPad;
        padding-left: v.$smallPad;
    }

    .gutter-fix {
        margin-left: -30px !important;
    }

    .search-btn {
        width: 78px !important;
    }

    .share-caption {
        opacity: 1;
    }

    @media (width <= v.$tablet) {
        --cds-text-field-width: 100%;

        .section-head {
            visibility: hidden;
            position: relative;
        }
        
        .section-head::before {
            visibility: visible;
            position: absolute;
            left: 0;
            display: block;
        }
        
        .benefits-title::before {
            content: 'Settings';
        }
        
        .options-title::before {
            content: 'Referrals';
        }

        .referrals-wrapper {
            display: flex;
            flex-direction: column;
        }
    
        .referral-benefits {
            margin-bottom: 0;
            margin-top: v.$regularPad;
            order: 2;
        }
    
        .referral-options {
            order: 1;
        }

        .menu-container {
            width: 100%;
        }

        .manage-box:nth-child(3) {
            order: 2;
        }

        .container-profile-style {
            padding: 0;
        }

        .search-bar {
            margin-bottom: v.$bigPad;
        }

        .search-bar-container {
            padding-right: v.$regularPad;
        }

        .referral-options {
            margin-bottom: 0;

            .options-inner {
                flex-direction: column;
                
                cds-large-button {
                    width: 100%;
                }
            }
        }

        .referral-benefits {
            margin-inline: 0;

            cds-large-button {
                max-width: unset;
                flex: 1 1 calc(50% - 10px);
                max-width: calc(50% - 10px);
                --cds-large-button-box-min-height: 72px;
            }

            .benefits-inner {
                justify-content: unset;
                flex-wrap: wrap
            }
        }

        .main-row {
            padding: unset;
        }

        .manage-referrals {
            .manage-inner {
                width: 100%;
                @include m.flex-column(initial, between);
            }

            .manage-box {
                width: 100% !important;
            }

            .stats-box {
                width: 100%;
            }

            cx-rate-of-change {
                max-width: 100%;
                height: 104px;
            }
        }

        .option .arrow {
            display: block !important;
        }

        .pagination-container {
            margin-top: 30px;
            margin-bottom: 4px;
        }

        section[data-simplebar] {
            max-height: unset;
            height: 590px !important;
        }

        .share-caption {
            opacity: .75;
        }
    }

    @media (width <= v.$phone) {
        .dialog {
            width: 290px;
        }

        .link-combo {
            .copy-button {
                display: none;
            }
        }

        .qr-button {
            margin-left: 16px;
        }

        .icons-container {
            @include m.flex(center, center);
            gap: 16px;
            width: 144px;
            flex-wrap: wrap;
        }

        .referral-options {
            margin-bottom: 0;
        }

        .filter-select {
            width: 100%;
            margin-right: v.$regularPad;
        }

        .filter-header {
            margin-top: 16px;
            justify-content: start;
            margin-bottom: v.$smallPad;
            padding-right: v.$regularPad;
        }

        .referral-benefits .benefits-inner {
            flex-direction: column;

            cds-large-button {
                flex: 1 1 100%;
                max-width: 100%;
            }
        }

        .benefits-inner {
            margin-top: 0;
        }

        .manage-referrals {
            .copy {
                width: 74px;
            }

            .manage-cta {
                line-height: 12px;
                letter-spacing: 0.13px;
            }

            .stats-box {
                flex-direction: column;
            }

            cx-rate-of-change {
                width: 100%;
            }
        }
    }

    .no-results {
        color: v.$black;
    }
}
