@use 'styles/variables' as v;
@use 'styles/mixins' as m;

$resolutions: (
    "sm": v.$phone,
    "md": v.$tablet,
    "lg": v.$desktop,
);

/*
    This will add the row class to the element and create all of the classes for columns.
    @param {Number} $cg-grid-spacing - The space between columns.
    @param {Number} $cg-grid-columns - The number of columns in the container. Should be a value between 2 and 12.
    @param {Boolean} $include-row - Specifies if the spacing should be also include vertically.
    @param ${Boolean} $include-resolutions - Specifies if the breakpoints classes should be generated.
*/
@mixin cg-grid($cg-grid-spacing: 16px, $cg-grid-columns: 12, $include-row: false, $include-resolutions: false) {
    @if ($cg-grid-columns > 12) {
        @error "Property cg-grid-columns shouldn't have a value over 12";
    }

    @if ($cg-grid-columns <= 1) {
        @error "Property cg-grid-columns should have a value of at least 2";
    }

    @include cg-grid-row($cg-grid-spacing, $include-row);

    .cg-col {
        @extend %cg-auto-col;
    }

    @for $col from 1 through $cg-grid-columns {
        @include create-col-class($cg-grid-spacing, $cg-grid-columns, $col);
    }

    @if ($include-resolutions) {
        @each $key, $value in $resolutions { 
            @for $col from 1 through $cg-grid-columns {
                @include create-col-class($cg-grid-spacing, $cg-grid-columns, $col, $key, $value);
            }
        }
    }
    
}

/*
    This will create the column class.
    @param {Number} $cg-grid-spacing - The space between columns.
    @param {Number} $cg-grid-columns - The number of columns in the container. Should be a value between 2 and 12.
    @param {Number} $col - The amount of grid columns that this element will take in width.
    @param ${String | null} $breakpoint - The abbreviation of the breakpoint
    @param ${Number | null} $resolution - The specific resolution in which the breakpoint will trigger.
*/
@mixin create-col-class($cg-grid-spacing, $cg-grid-columns, $col, $breakpoint: null, $resolution: null) {
    $bp: if($breakpoint, '-#{$breakpoint}', '');
    .cg-col#{$bp}-#{$col} {
        @include cg-col($cg-grid-spacing, $cg-grid-columns, $col, $resolution);
    }
}

/*
    Will add the styles for the parent grid.
    @param {Number} $cg-grid-spacing - The space between columns.
    @param {Boolean} $include-row - Specifies if the spacing should be also include vertically.
*/
@mixin cg-grid-row($cg-grid-spacing, $include-row: false) {
    @include m.flex-gap($cg-grid-spacing);

    @if ($include-row == false) {
        row-gap: 0;
    }
    flex-wrap: wrap;
}

/*
    Will add the styles for a specific column.
    @param {Number} $cg-grid-spacing - The space between columns.
    @param {Number} $cg-grid-columns - The number of columns in the container. Should be a value between 2 and 12.
    @param {Number} $col - The amount of grid columns that this element will take in width.
    @param ${Number | null} $resolution - The specific resolution in which the styles will be applied.
*/
@mixin cg-col($cg-grid-spacing, $cg-grid-columns, $col, $resolution: null) {
    $spaces: $cg-grid-columns - 1;
    $spacing: $spaces * $cg-grid-spacing;
    $column-width: calc(calc(100% - $spacing) / $cg-grid-columns);
    $width: calc(($column-width * $col) + ($cg-grid-spacing * ($col - 1)));

    @if ($resolution) {
        @include m.media($resolution, false) {
            max-width: $width;
        }
    }
    @else {
        max-width: $width;
    }
    @extend %cg-col-style;
    
}

// Will add the styles for an auto-fitting column.
@mixin cg-auto-col() {
    @extend %cg-auto-col;
}

%cg-col-style {
    @include m.container-fluid();
    flex: unset;
}

%cg-auto-col {
    flex: 1 0 0;
    overflow: hidden;
}
