@use 'styles/variables' as v;
@use 'styles/mixins' as m;
@import "styles/animations";

#profile {
    .profile-value {
        @include m.flex-gap(4px);

        &:has(.skeleton-loading) {
            width: 100%;
        }
    }

    .profile-member {
        line-height: 16px;

        .profile-subtitle {
            display: inline-block;
        }
    }

    .member-since-container {
        @include m.flex-gap(4px, center);
        margin-top: 4px;
    }

    .profile-separator {
        border-bottom: v.$regular-border;
        width: 100%;

        &--main {
            margin-top: v.$regularPad;
        }
    }

    .notifications-content {
        row-gap: v.$regularPad !important;
    }
    
    .notification {
        &__section {
            flex: 1;
        }
        
        &__title {
            @include m.default-font();
            @include m.font(v.$regular, 500, 16px, 0.28px);
            display: inline-block;
            margin-bottom: 12px !important;
        }

        &__separator {
            margin-bottom: v.$regularPad !important;
        }

        &__checkboxes {
            @include m.column-gap(v.$regularPad);
        }
    }

    .secondary-style {
        background-color: rgb(111, 87, 190);
    }
    
    .unlink-btn {
        @include m.container-fluid(248px);

        button { 
            width: 100%;
        }
    }

    .profile-main-container {
        display: flex;
        flex-wrap: wrap;
        column-gap: v.$smallPad;
        margin-bottom: v.$bigPad;
    }

    .container-profile-style,
    .container-profile-style-settings {
        padding: v.$regularPad;

        @media (width <= v.$desktop) {
            border-radius: 0;
        }
    }

    .container-profile-style {
        margin-bottom: v.$bigPad;
    }

    .content-desc {
        @include m.column-gap(16px);
    }

    .text-profile {
        position: relative;
        flex: 1;
    }
    
    .style-title {
        line-height: 28px !important;
        letter-spacing: 0.448px;
        margin-bottom: v.$regularPad !important;
    }

    @media (width <= 364px) {
        .profile-address {
            margin-left: 0 !important;
        }
    }
 
    .settings-title {
        @include m.font(v.$extralarge, 500, 28px);
        display: inline-block;
        margin-bottom: 4px;

        &.skeleton-loading {
            width: 95px;
            height: 28px;
        }
    }

    .profile-desc {
        font-size: v.$regular;
    }

    .info-desc {
        @include m.font(v.$regular, 400, null, 0.014px);
        font-style: normal;
    }

    .profile-field,
    .profile-info {
        @include m.font(v.$regular, null, 16px, 0.14px);
    }

    .profile-field {
        @include m.container-fluid(100px);
        color: rgb(133, 133, 133);
    }

    .profile-name {
        @include m.font(v.$extralarge, 500, 28px, 0.24px);
    }

    .profile-name,
    .settings-title,
    .style-title,
    .info-text,
    .info-desc {
        color: v.$color-grey-1100;
    }

    .info-text {
        @include m.font(v.$regular !important, 500, 16px);
        margin-bottom: v.$regularPad;
        flex-basis: 100%;
    }

    .info-field {
        gap: 52px;
    }

    section[data-simplebar] {
        max-height: 335px;
    }

    @include m.media(v.$phone) {
        .info-text {
            margin-bottom: 9px;
        }

        .profile-name {
            font-weight: 500;
        }
    }

    .profile-grid {
        @include m.flex-top-bottom(".profile-large-button", v.$desktop);
    }

    @media (min-width: v.$tablet) {
        .profile-picture img {
            @include m.square(90px);
        }
    }

    @include m.media(v.$desktop) {
        padding-inline: v.$regularPad;

        &.is-unlink .container-profile-style-settings .profile-subtitle { 
            margin-bottom: v.$bigPad !important;
        }

        .container-profile-style,
        .container-profile-style-settings {
            margin-bottom: 0;
            width: auto;
            max-width: unset;
        }
        
        .container-profile-style {
            border-radius: 0;
            padding: 0;
        }

        .container-profile-style-settings {
            padding: 0;
            padding-top: v.$bigPad;
        }
    
        .notification__separator {
            @include m.container-fluid(586px);
        }
    }

    @media (width <= v.$tablet) {
        .container-profile-style {
            width: auto;
            margin-block: 0;
        }

        .info-text {
            margin-bottom: v.$smallPad;
        }

        .container-profile-style-settings {
            margin-bottom: 0;
            width: auto;
        }
    }

    @media (v.$phone < width <= v.$tablet) {
        .info-field:first-child {
            flex-basis: 282px;
            gap: 18.5%;
        }

        .info-field:last-child {
            max-width: none;
            flex: 1;
            gap: 12.2%;
        }
    }

    @media (width <= v.$phone) {
        .info-field {
            width: 100% !important;
            gap: v.$bigPad;
        }

        .info-icon {
            margin-left: auto;
        }

        .unlink-btn {
            max-width: 100%;
        }

        .profile-value {
            flex: 1;
            min-width: 50%;
            max-height: 16px;
        }
    }

    .profile-field.skeleton-loading {
        width: 100%;
        min-width: 0;
        max-width: 100px;
        height: 16px;
    }

    .profile-info.skeleton-loading {
        width: 60%;
        height: 16px;
    }
}
