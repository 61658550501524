@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#balance-history {
    &.balance-view-container {
        @include m.flex-gap(v.$smallPad, $direction: column);

        cds-back-button {
            z-index: 10;
        }

        @media (width <= v.$desktop) {
            gap: v.$regularPad;
        }
    }

    .title {
        font-family: v.$primaryfont;
        font-size: v.$extralarge !important;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 5px !important;
        color: v.$black;

        @media (width <= v.$desktop) {
            margin-bottom: 0 !important;
        }
    }

    .orders-container {
        padding-inline: v.$regularPad;
        padding-top: v.$regularPad;
        padding-bottom: v.$bigPad;
        @include m.flex-gap(v.$bigPad, $direction: column);

        @media (width > v.$desktop) {
            height: 701px;

            &__empty {
                height: unset;
            }
        }

        @media (width <= v.$desktop) {
            padding: 0;
            border-radius: 0;
            box-shadow: none;
            background: transparent;
        }
    }

    .search-bar {
        border-radius: 4px;
    }

    .mdc-text-field__input {
        font-size: v.$regular;

        @media (width <= v.$desktop) {
            font-size: v.$medium;
        }

        &::placeholder {
            font-size: v.$regular;

            @media (width <= v.$desktop) {
                font-size: v.$small;
            }
        }
    }

    #cx-text-input .cx-search-input .mdc-notched-outline .mdc-notched-outline {
        &__trailing,
        &__leading {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    .sort-container {
        @include m.flex(center, space-between);

        > p {
            font-family: v.$primaryfont;
            font-size: v.$small;
            font-weight: 300;
            line-height: 14px;
            letter-spacing: 0.45px;
            text-align: left;
            margin-bottom: 0 !important;
            color: v.$gray-blue;

        }
    }

    cx-filter {
        max-width: 188px;

        @media (width <= v.$phone) {
            max-width: unset;
            width: 100%;
        }
    }

    .no-results {
        @include m.flex-gap(v.$regularPad, center, center, column);
        > img {
            width: 176px;
            height: 154px;
        }

        > p {
            font-family: v.$primaryfont;
            font-size: v.$medium;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.001em;
            text-align: center;
            color: v.$gray-blue;
            margin-bottom: 0 !important;
            max-width: 380px;

            @media (width <= v.$phone) {
                max-width: 280px;
            }
        }
    }

    .mobile-search {
        @include m.flex-gap(v.$regularPad);
    }

    cx-search-drawer {
        @include m.container-fluid(75px);
    }

    .pagination {
        margin-bottom: 0 !important;
    }

    .pagination a {
        @include m.square(40px, true);
        margin-inline: 7.5px !important;

        @media (width <= v.$desktop) {
            @include m.square(30px, true);
        }
    }
}
