@use 'styles/cg-grid' as grid;
@use 'styles/mixins' as m;
@use 'styles/variables' as v;

#orders {
    .title.default.skeleton-loading {
        width: 76px;
        height: 30px;
        display: inline-block !important;
    }
    
    .description.profile-subtitle.skeleton-loading {
        width: 180px;
        height: 16px;
        display: inline-block !important;
    }
}
