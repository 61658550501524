@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#security {
    .security-view-container {
        padding: v.$regularPad;
        max-width: 928px;
        @include m.flex-gap(v.$regularPad, $direction: column);

        @media (width <= v.$desktop) {
            padding: 0;
            border-radius: 0;
            box-shadow: none;
            background: transparent;
        }

        p, h1, h2 {
            margin-bottom: 0 !important;
        }
    }

    .title-container {
        @include m.flex-gap(v.$smallPad, $direction: column);
    }

    .options-container {
        @include m.flex-gap(v.$regularPad, $direction: column);
    }

    .page-title,
    .page-subtitle {
        color: v.$black;
        font-family: v.$primaryfont;
    }

    .page-title {
        font-size: v.$extralarge;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.448px;
    }

    .page-subtitle {
        font-size: v.$regular;
        font-weight: 400;
        line-height: 14px;
        margin-bottom: v.$regularPad !important;
        opacity: 0.5;
    }

    // original security
    .single-container {
        background: v.$white;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        flex: 1 1 auto;
        min-height: 124px;
        height: auto;
        padding: 37px 21px;
        flex-flow: column;
    }

    .email-container {
        background-color: v.$white;
        padding: 10px;
        border-radius: 12px;
        height: 345px;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);

        @media (max-width: v.$desktop) {
            height: 328px;
            margin-top: 2rem;
        }
    }

    .email-icon {
        max-width: 58px;
    }

    .info-text {
        padding: 10px;
        border-radius: 12px;
        background-color: v.$inputgray;
        color: v.$darkergray;
    }

    .warning-text {
        padding: 19px 10px;
        border-radius: 12px;
        background-color: v.$off-yellow;
        color: v.$darkergray;
    }

    .qr-box {
        padding: 12px;
        border-radius: 4px;
        background-color: v.$divisor-gray;
    }

    .green-text {
        padding: 19px 10px;
        border-radius: 12px;
        background-color: v.$off-green;
    }

    .mini-spinner-icon {
        position: absolute;
        right: 15px;
        top: 12px;
    }

    .circle-icon {
        position: absolute;
        height: 20px;
        width: 20px;
        right: 15px;
        top: 15px;
    }

    .mini-spinner-icon,
    .circle-icon {
        &.deactive {
            top: unset;
        }
    }

    button {
        height: 50px;
    }

    .two-factor-label {
        width:48%;
        height: 50px;
        @media (max-width: v.$desktop) and (min-width: v.$tablet) {
            width: 100%;
        }

        @media (max-width: v.$phone) {
            width: 100%;
        }
    }

    .two-factor-button {
        float: right;
        width: 50%;
        @media (max-width: v.$desktop) and (min-width: v.$tablet) {
            width: 100%;
        }

        @media (max-width: v.$phone) {
            width: 100%;
        }
    }

    .qr-code-img img {
        width: 90px;
        height: 94px;
    }

    .input-password:not(.mdc-text-field--disabled) .mdc-text-field__input{
        padding-right: 20px;
    }
    
    .link-email {
        color: v.$cx-purple;
    
        &:hover {
            text-decoration: underline !important;
        }
    }

    @media (max-width: v.$tablet) {
        .warning-text {
            padding: 10px 10px 11px;
        }
    
        .green-text {
            padding: 10px 10px 11px;
        }

        .qr-box {
            padding: 5px;
        }
    }
}
