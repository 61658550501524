@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#balance {
    .balance-view-container {
        padding: v.$regularPad;
        @include m.flex-gap(v.$regularPad, $direction: column);

        @media (width <= v.$desktop) {
            padding: 0;
            border-radius: 0;
            box-shadow: none;
            background: transparent;
        }

        p, h1, h2 {
            margin-bottom: 0 !important;
        }
    }

    .title-container {
        @include m.flex-gap(8px, $direction: column);
    }

    .page-subtitle {
        @include m.font(v.$regular, 400, 16, 0.035px, v.$color-grey-700);
        font-family: v.$primaryfont;
        
    }

    .page-title {
        @include m.font(v.$extralarge, 500, 28px, 0.24px, v.$color-grey-900);
        font-family: v.$primaryfont;
    }

    .page-subtitle {
        font-size: v.$regular;
        font-weight: 400;
        line-height: 14px;
        margin-bottom: v.$regularPad !important;
    }
}
